import React, {Component} from 'react';
import '../styles/home.scss';
import {withTranslation} from "react-i18next";
import englishImage from '../images/london.svg';
import {Link} from "react-router-dom";
import ContactForm from "./contact-form.component";
import MetaTags from 'react-meta-tags';

class HomeComponent extends Component {

    constructor() {
        super();
        this.showUseCpf = this.showUseCpf.bind(this)
        this.closeUseCpf = this.closeUseCpf.bind(this)
        this.state = {
            showUseCpf: false,
            modalAdditionalMessage: ""
        }

    }

    componentDidMount() {
        document.title = 'Cours de langues étrangers particuliers - talkeasy.fr'
    }

    closeUseCpf() {
        this.setState({
            showUseCpf: false
        })
    }

    showUseCpf() {
        this.setState({
            showUseCpf: true
        })
    }

    render() {
        return (
            <>
                <MetaTags>
                    <title>Cours d'anglais - talkeasy.fr</title>
                    <meta name="description" content="Cours d'anglais avec talkeasy.fr.
                    L'apprentissage des langues étrangers en ligne est encore plus facile et abordable avec talkeasy.fr
                Nous nous adaptons à vous et nous dévélopons pour chaque élève une approche unique.
                Nous vous proposons des cours individuels et les cours en groupe"/>
                </MetaTags>
                <ContactForm
                    show={this.state.showUseCpf}
                    handleClose={this.closeUseCpf}
                    additionalMessage={this.state.modalAdditionalMessage}
                />
                {/*    <div className={"img-jumbotron"}>
                </div>*/}
                <div className="py-4 container-homepage">
                    <div className="px-4 text-center talkeasy-home-image talkeasy-home-gradient">
                        <div className="col-lg-6 mx-auto">
                            <h2>Maîtrisez l'anglais depuis chez vous !</h2>
                            <p>
                                Que ce soit pour le travail, les voyages, les études ou élargir votre cercle social, nos
                                professeurs particuliers vous guident vers la réussite !
                            </p>
                            <ul className="enhanced-list list-group list-group-flush">
                                <li className="text-muted list-group-item bg-transparent justify-content-center">
                                    Apprenez l'anglais efficacement avec nous.
                                </li>
                                <li className="list-group-item bg-transparent justify-content-center">
                                    <div className="link-primary" onClick={() => {
                                        this.setState({modalAdditionalMessage: "Demande du premier cours : "});
                                        this.showUseCpf();
                                    }}>
                                        Profitez d'un cours d'essai gratuit pour découvrir notre méthode unique
                                    </div>
                                </li>
                                <li className="text-muted list-group-item bg-transparent justify-content-center">
                                Programmes personnalisés adaptés à vos besoins spécifiques
                                </li>
                                <li className="text-muted list-group-item bg-transparent justify-content-center">
                                    Cours disponibles pour tous les niveaux, du débutant à l'avancé
                                </li>
                                <li className="text-muted list-group-item bg-transparent justify-content-center">
                                    Choisissez entre des sessions individuelles ou en groupe, selon votre préférence
                                </li>
                            </ul>

                            <div className="p-2">
                                <div className="cta-container">
                                    <div className="cta-box">
                                        <button
                                            className="cta-button"
                                            onClick={() => {
                                                this.setState({modalAdditionalMessage: "Demande de cours d'essai : "});
                                                this.showUseCpf();
                                            }}
                                        >
                                            DEMANDEZ UN COURS D'ESSAI
                                        </button>
                                        <p className="cta-text">Appelez-nous pour plus de détails</p>
                                        <a href="tel:+33627356295" className="cta-phone">
                                            <i className="bi bi-phone"></i> 06 27 35 62 95
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row g-3 text-center div-circles"}>
                    <div className="card" style={{width: '18rem'}}>
                        <i className="bi bi-webcam  primary-color" style={{fontSize: '50px'}}></i>
                        <div className="card-body">
                            <p className="card-text">
                                Cours 100% en ligne
                            </p>
                        </div>
                    </div>
                    <div className="card" style={{width: '18rem'}}>
                        <i className="bi bi-person-check-fill primary-color" style={{fontSize: '50px'}}></i>

                        <div className="card-body">
                        <p className="card-text">Suivie personnalisé pour chaque élève</p>
                        </div>
                    </div>
                    <div className="card" style={{width: '18rem'}}>
                        <i className="bi bi-translate primary-color" style={{fontSize: '50px'}}></i>
                        <div className="card-body">
                            <p className="card-text">
                                Amèliorez significativement votre niveau oral et écrit
                            </p>
                        </div>
                    </div>
                    <div className="py-4 container-homepage">
                        <div className={"container   "}>
                            <h2>Explorez le Monde des Langues Étrangères en Ligne avec Nos Professeurs Particuliers</h2>
                            <p>
                                Bienvenue chez <Link to={"/"}>talkeasy</Link>, votre plateforme d'apprentissage des
                                langues étrangères en ligne où l'expérience est facile, amusante et abordable.
                                Chez talkeasy, nous croyons en l'unicité de chaque étudiant, c'est pourquoi nous
                                développons une approche personnalisée pour chaque élève.
                                Prêt à explorer le monde des langues ? Choisissez parmi nos cours
                                individuels ou nos cours en groupe
                                et embarquez pour une aventure linguistique enrichissante !
                            </p>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(HomeComponent);