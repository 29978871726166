import React from "react"
import os from"os";


class apiAddr extends React.Component {

    static apiAddress() {
        let hostname = os.hostname();
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return hostname + ':8080';
        } else {
            return "";
        }
    }
}
export default apiAddr;