import axios from 'axios';
import authHeader from './auth-header';
import Addr from "../apiAddr";


class VisitorService {

    pushVisitorEvent() {
        console.log("TEST push visitor Event" + Addr.apiAddress() + "/api/visitor" )
        return axios.post(Addr.apiAddress() + "/api/visitor", {
                page: window.location.href
            },
            {headers: authHeader()});
    }
}


export default new VisitorService();
