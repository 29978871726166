import React, {Component} from "react";
import {Link} from "react-router-dom";

import AuthService from "../services/auth.service";
import UserService from '../services/user.service';
import EventBus from "../common/EventBus";
import '../styles/sidebar.scss';
import $ from 'jquery'
import {withTranslation} from 'react-i18next';


class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);
        this.onClickSidebarCollapse = this.onClickSidebarCollapse.bind(this);
        this.onClickCloseSidebar = this.onClickCloseSidebar.bind(this);
        this.state = {
            isAManager: false,
            showModeratorBoard: false,
            showAdminBoard: false,
            currentUser: undefined,
            sideBarActive: ""
        };
    }

    async componentDidMount() {
        const user = AuthService.getCurrentUser();
        if (user) {
            let currentUser = (await UserService.getMyProfile(user.id)).data
            this.setState({
                currentUser: currentUser,
                showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
                showAdminBoard: user.roles.includes("ROLE_ADMIN"),
            });
            UserService.getRoles().then(roles => {
                if (roles.data.includes('manager')) {
                    this.setState({
                        isAManager: true
                    });
                }
            })

        }

        EventBus.on("logout", () => {
            this.logOut();
        });
    }

    onClickSidebarCollapse() {
        $('#sidebar').addClass('active');
        $('.overlay').addClass('active');
        $('.collapse.in').toggleClass('in');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
    }

    onClickCloseSidebar() {
        $('#sidebar').removeClass('active');
        $('.overlay').removeClass('active');
    }

    componentWillUnmount() {
        EventBus.remove("logout");
    }

    logOut() {
        this.onClickCloseSidebar();
        AuthService.logout();
        window.location.reload();
        this.setState({
            showModeratorBoard: false,
            showAdminBoard: false,
            currentUser: undefined,
        });
    }

    render() {
        const {t} = this.props;
        const {currentUser, showAdminBoard} = this.state;

        return (
            <>
                <nav id="sidebar">
                    <div id="dismiss">
                        <button type="button" className="btn-close" aria-label="Close"
                                onClick={this.onClickCloseSidebar}></button>
                    </div>

                    <div className="sidebar-header">
                        <a href="/"
                           className="d-flex align-items-center mb-md-0 me-md-auto link-dark text-decoration-none">
                            <span className="fs-4">talkeasy.fr</span>
                        </a>
                    </div>

                    <nav className="navbar navbar-expand-lg navbar-light bg-light navbar-side"
                         aria-label="Tenth navbar example">
                        <div className="justify-content-md-center ">
                            <div className="d-flex flex-column flex-shrink-0 p-3 bg-light navbar-light bg-light"
                                 style={{width: '280px'}}>
                                <hr/>
                                <ul className="nav nav-pills flex-column mb-auto">
                                    <li>
                                        <Link aria-current="page" className="nav-link link-dark" to={"/"}
                                              onClick={this.onClickCloseSidebar}>
                                            {t('Accueil')}
                                        </Link>
                                    </li>
                                   {/* <li>
                                        <div className="dropdown">
                                            <a href="/"
                                               className="d-flex align-items-center link-dark text-decoration-none dropdown-toggle"
                                               id="dropdownUser2" data-bs-toggle="dropdown"
                                               aria-expanded="false">
                                                Nos cours
                                            </a>
                                            <ul className="dropdown-menu text-small shadow"
                                                aria-labelledby="dropdownUser2">

                                                <li>
                                                    <Link aria-current="page" className="dropdown-item"
                                                          to={"/intro/individuel"} onClick={this.onClickCloseSidebar}>
                                                        {t('Cours individuels')}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link aria-current="page" className="dropdown-item"
                                                          to={"/intro/groupe"} onClick={this.onClickCloseSidebar}>
                                                        {t('Cours en groupe')}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>*/}
                                   {/* <li>
                                        <div className="dropdown">
                                            <a href="/"
                                               className="d-flex align-items-center link-dark text-decoration-none dropdown-toggle"
                                               id="dropdownUser2" data-bs-toggle="dropdown"
                                               aria-expanded="false">
                                                Nos langues
                                            </a>
                                            <ul className="dropdown-menu text-small shadow"
                                                aria-labelledby="dropdownUser2">

                                                <li>
                                                    <Link aria-current="page" className="dropdown-item"
                                                          to={"/english"} onClick={this.onClickCloseSidebar}>
                                                        {t('Anglais')}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link aria-current="page" className="dropdown-item"
                                                          to={"/italian"} onClick={this.onClickCloseSidebar}>
                                                        {t('Italien')}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>*/}
                                    {/*   <li>
                                        <Link className="nav-link link-dark" to={"/tarif"}
                                              onClick={this.onClickCloseSidebar}>
                                            {t('Nos formules')}
                                        </Link>
                                    </li>*/}
                                    {/* <li>
                                        <Link className="nav-link link-dark" to={"/cpf"}
                                              onClick={this.onClickCloseSidebar}>
                                            {t('Qu\'est-ce-que le CPF ?')}
                                        </Link>
                                    </li>*/}
                                    {/* <li>
                                        <Link className="nav-link link-dark" to={"/about-us"}
                                              onClick={this.onClickCloseSidebar}>
                                            {t('Qui sommes nous')}
                                        </Link>
                                    </li>*/}
                                    <li>
                                        <Link className="nav-link link-dark" to={"/contact-us"}
                                              onClick={this.onClickCloseSidebar}>
                                            {t('Nous contacter')}
                                        </Link>
                                    </li>

                                    {currentUser ? (
                                        <>
                                            <hr/>
                                            <div className="mb-3">
                                                <div className="dropdown">
                                                    <a href="/"
                                                       className="d-flex align-items-center link-dark text-decoration-none dropdown-toggle"
                                                       id="dropdownUser2" data-bs-toggle="dropdown"
                                                       aria-expanded="false">
                                                        <strong>{currentUser.email}</strong>
                                                    </a>
                                                    <ul className="dropdown-menu text-small shadow"
                                                        aria-labelledby="dropdownUser2">
                                                        <li>
                                                            <Link aria-current="page" className="dropdown-item"
                                                                  to={"/profile"} onClick={this.onClickCloseSidebar}>
                                                                {t('Profile')}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link aria-current="page" className="dropdown-item"
                                                                  to={"/lessons"} onClick={this.onClickCloseSidebar}>
                                                                {t('Cours individuels')}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link aria-current="page" className="dropdown-item"
                                                                  to={"/group"} onClick={this.onClickCloseSidebar}>
                                                                {t('Cours en groupe')}
                                                            </Link>
                                                        </li>
                                                        {showAdminBoard ? (
                                                            <li>
                                                                <Link aria-current="page" className="dropdown-item"
                                                                      to={"/admin"}
                                                                      onClick={this.onClickCloseSidebar}>
                                                                    {t('Administrer le site')}
                                                                </Link>
                                                                <Link aria-current="page" className="dropdown-item"
                                                                      to={"/dash"}
                                                                      onClick={this.onClickCloseSidebar}>
                                                                    {t('Dash v2')}
                                                                </Link>

                                                            </li>
                                                        ) : null
                                                        }
                                                        <li>
                                                            <hr className="dropdown-divider"/>
                                                        </li>
                                                        <li>
                                                            <Link aria-current="page" className="dropdown-item" to={"/"}
                                                                  onClick={this.logOut}>
                                                                {t('Sign out')}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="buttons-login">
                                            {/*  <Link className="btn btn-outline-primary me-2" to={"/login"}
                                                  onClick={this.onClickCloseSidebar}>
                                                {t('Sign in')}
                                            </Link>
                                            <Link className="btn btn-primary" to={"/register"}
                                                  onClick={this.onClickCloseSidebar}>
                                                {t('Sign up')}
                                            </Link>*/}
                                        </div>
                                    )
                                    }

                                </ul>
                            </div>
                        </div>

                    </nav>
                </nav>

            </>

        );
    }
}

export default withTranslation()(Sidebar);





